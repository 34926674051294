<template>
  <v-dialog :value="true" max-width="600" @keydown="onKeyDown" @click:outside="onClose">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn>
    </template>
    <v-card class="px-8">
      <v-card-title class="text-h5 pl-0"> Chuyển trạng thái </v-card-title>
      <v-row class="mt-4">
        <v-col cols="6">
          <v-select
            v-model="statusId"
            :items="options"
            disabled
            item-text="statusName"
            item-value="statusId"
            no-data-text="Không có dữ liệu"
            prepend-inner-icon="mdi-current-ac"
            outlined
            label="Hiện tại"
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="statusDestination"
            :items="optionsDestination"
            item-value="statusId"
            item-text="statusName"
            outlined
            label="Chuyển thành"
            no-data-text="Không có dữ liệu"
            prepend-inner-icon="mdi-list-status"
            clearable
          >
            <template #selection="data">
              <v-chip
                class="white--text"
                :input-value="data.selected"
                :color="judgementColor(data.item.statusId)"
              >
                {{ data.item.statusName }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="onClose"> Hủy </v-btn>
        <v-btn color="green darken-1" text @click="onSave"> Đồng ý </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  PRESCRIPTION_STATUS_DIALOG,
  EMPTY,
  PRESCRIPTION_STATUS,
  PRESCRIPTION_STATUS_COLOR,
} from "~/utils/constants";

export default {
  name: "TransitionDialog",
  props: {
    statusId: {
      type: Number,
      default: null,
    },
    prescriptionId: {
      type: Number,
      default: null,
    },
    backUrl: {
      type: String,
      default: EMPTY,
    },
  },
  data() {
    return {
      options: PRESCRIPTION_STATUS_DIALOG,
      optionsDestination: [],
      statusDestination: null,
    };
  },
  created() {
    this.optionsDestination = PRESCRIPTION_STATUS_DIALOG.filter(
      (element) => element.statusId !== this.statusId
    );
  },
  methods: {
    judgementColor(statusId) {
      switch (Number(statusId)) {
        case PRESCRIPTION_STATUS.DYCCLD:
          return PRESCRIPTION_STATUS_COLOR.DYCCLD;
        case PRESCRIPTION_STATUS.DLDCBT:
          return PRESCRIPTION_STATUS_COLOR.DLDCBT;
        case PRESCRIPTION_STATUS.DBTCST:
          return PRESCRIPTION_STATUS_COLOR.DBTCST;
        case PRESCRIPTION_STATUS.DSTCG:
          return PRESCRIPTION_STATUS_COLOR.DSTCG;
        case PRESCRIPTION_STATUS.GLT:
          return PRESCRIPTION_STATUS_COLOR.GLT;
        case PRESCRIPTION_STATUS.HT:
          return PRESCRIPTION_STATUS_COLOR.HT;
        default:
        // code block
      }
    },
    onClose() {
      this.$emit("close");
    },
    onKeyDown(e) {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    },
    async onSave() {
      await this.$services.prescriptionService.updatePrescriptionStatus(
        this.prescriptionId,
        {
          statusId: this.statusDestination,
        }
      );
      this.$router.push(this.backUrl);
      this.$emit("close");
    },
  },
};
</script>
