<template>
  <v-container class="bg-color-3 text-color-4 pl-8 py-6 rounded-md font-medium text-base">
    <v-row>
      <v-col>
        <span>Tên bài thuốc: </span>
        <span>{{ name }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span>Triệu chứng: </span>
        <span>{{ symptom }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OverviewPrescriptionBase",
  props: {
    prescriptionBase: {
      type: Object,
      default: () => ({
        name: "Nguyễn Lê Trung Kiên",
        symptom: "Đặc trị",
      }),
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      name: (state) => state.prescriptionBase.name,
      symptom: (state) => state.prescriptionBase.symptom
    })
  },
  methods: {},
};
</script>
