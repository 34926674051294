<template>
  <v-dialog
    :value="true"
    max-width="80%"
    style="height: calc(100vh - 20%)"
    @keydown="onKeyDown"
    @click:outside="onClose"
  >
    <v-card>
      <v-card-title>Đơn thuốc</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col class="flex space-x-4">
                <h3 class="font-bold text-lg">Tổng số vị: {{ total }}</h3>
                <h3 class="font-bold text-lg">Số thang: {{ quantity }}</h3>
              </v-col>
            </v-row>
            <v-row class="flex-nowrap overflow-x-auto">
              <v-col v-for="(item, k) in prescription" :key="item.columnId" cols="auto">
                <CardMedicine
                  v-for="(element, index) in item.children"
                  :key="index"
                  :is-show-deleted-icon="false"
                  :card="element"
                  :medicineList="medicineList"
                  :ordered="calculateIndex(k, prescription, index)"
                  class="mt-5 w-400/16"
                  disabled
                />
              </v-col>
            </v-row>
            <v-row class="text-base font-bold">
              <v-col>
                <u>Thành tiền</u>:
                <strong class="text-black">{{ price }}</strong>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import PatientInformation from "~/components/organisms/prescription/PatientInformation";
import CardMedicine from "~/components/atoms/CardMedicine";
import { calculateIndex } from "~/utils";

export default {
  name: "PrescriptionDialog",
  props: {
    prescription: {
      type: Array,
      default: () => [],
    },
    price: {
      type: String,
      default: "0",
    },
    quantity: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PatientInformation,
    CardMedicine,
  },

  data() {
    return {
      valid: true,
      errors: [],
    };
  },

  computed: {
    ...mapState({
      medicineList: (state) => state.medicineList,
    }),

    total() {
      return this.prescription.reduce(
        (accumulator, currentValue) => accumulator + currentValue.children.length,
        0
      );
    },
  },

  watch: {},

  methods: {
    calculateIndex,
    onClose() {
      this.$emit("close");
    },
    onKeyDown(e) {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  opacity: 0.8;
  border: 2px solid transparent;
  border-radius: 5px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
