<template>
  <div>
    <component :is="component" v-bind="props" @close="onClose" />
  </div>
</template>

<script>
import PrescriptionDialog from "~/components/organisms/dialog/PrescriptionDialog";
import OldPrescriptionDialog from "~/components/organisms/dialog/OldPrescriptionDialog";
import PrescriptionBaseDialog from "~/components/organisms/dialog/PrescriptionBaseDialog";
import TransitionDialog from "~/components/organisms/dialog/TransitionDialog";
import PrescriptionListDialog from "~/components/organisms/dialog/PrescriptionListDialog";

export default {
  name: "Dialog",
  components: {
    PrescriptionDialog,
    OldPrescriptionDialog,
    PrescriptionBaseDialog,
    TransitionDialog,
    PrescriptionListDialog,
  },
  data() {
    return {
      component: null,
      props: null,
    };
  },
  created() {
    this.$eventBus.$on("open-dialog", (input) => {
      console.log("🚀 ~ file: Index.vue:31 ~ this.$eventBus.$on ~ input:", input)
      this.component = input?.component || null;
      this.props = input?.props || null;
    });
  },
  methods: {
    onClose() {
      this.component = null;
      this.props = null;
    },
  },
  beforeDestroy() {
    this.$eventBus.$off("open-dialog");
  },
};
</script>
