<template>
  <v-container class="bg-color-3 text-color-4 rounded-md font-medium text-base">
    <v-row>
      <v-col>
        <span>Bệnh nhân: </span>
        <span>{{ patientInfo.patientName }}</span>
      </v-col>
      <v-col>
        <span>Tuổi: </span>
        <span>{{ patientInfo.age }}</span>
      </v-col>
      <v-col>
        <span>Giới tính: </span>
        <span>{{ patientInfo.gender }}</span>
      </v-col>
      <v-col>
        <span>Ngày lên đơn: </span>
        <span>{{ patientInfo.doctorAt }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span>Lí do: </span>
        <span>{{ patientInfo.reason }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PatientInformation",
  computed: {
    patientInfo() {
      return this.$store.getters["prescription/getPatientInformation"];
    }
  }
};
</script>
