<template>
  <v-container>
    <v-row class="justify-space-between items-center">
      <h3 class="font-bold text-lg mb-4">Tổng số vị: {{ total }}</h3>
      <InputNumber
        v-model="modelNumberColumn"
        :min="0"
        :max="100"
        :step="1"
        centeredTextInput
        label="Số cột"
        class="max-w-160/16"
      />
    </v-row>
    <v-row class="flex-nowrap overflow-x-scroll">
      <Container
        orientation="horizontal"
        @drop="onColumnDrop($event)"
        drag-handle-selector=".column-drag-handle"
        :drop-placeholder="upperDropPlaceholderOptions"
      >
        <Draggable
          v-for="(column, colIndex) in scene"
          :key="column.columnId"
          :tag="{
            value: 'div',
            props: { class: 'pr-4' },
          }"
        >
          <ColumnMedicine
            ref="columnMedicine"
            :list="column.children"
            :columnId="column.columnId"
            :startIndex="startIndex(colIndex)"
            :isValidated="isValidated"
            @drop-card="onCardDrop"
            @check:duplicate="checkDuplicate"
            @remove-column="onRemoveColumn"
            @remove-row="onRemoveRow"
            @check:mass="
              (medicineId, cellIndex) => checkMass(medicineId, colIndex, cellIndex)
            "
            @change-focus-left="onChangeFocusLeft"
            @change-focus-right="onChangeFocusRight"
            @create-new-column="onCreateNewColumn"
          />
        </Draggable>
      </Container>
    </v-row>
  </v-container>
</template>

<script>
import { Container, Draggable } from "vue-dndrop";
import { applyDrag, generateItems } from "~/utils";
import ColumnMedicine from "~/components/molecules/prescription/ColumnMedicine";
import InputNumber from "~/components/molecules/InputNumber";

export default {
  name: "MedicineSection",
  components: { Container, Draggable, ColumnMedicine, InputNumber },
  props: {
    scene: {
      type: Array,
      default: () => [],
    },
    isValidated: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "300",
        showOnTop: true,
        numberColumn: null,
      },
      dupplicateError: [],
      count: 0,
      currentColumActive: 0,
    };
  },

  computed: {
    modelNumberColumn: {
      get() {
        return this.scene.length;
      },
      set(value) {
        const length = this.scene.length;
        if (value > length) {
          this.scene.push({
            columnId: length + 1,
            children: generateItems(1, (j) => ({
              medicineId: null,
              mass: null,
              isHidden: false,
              isDupplicate: false,
              isOverMass: false,
            })),
          });
        } else {
          this.scene.splice(this.scene.length - 1, 1);
        }
      },
    },

    total() {
      return this.scene.reduce(
        (accumulator, currentValue) => accumulator + currentValue.children.length,
        0
      );
    },
  },

  methods: {
    onChangeFocusLeft(columnId) {
      if (columnId - 1 > 0) {
        this.$refs.columnMedicine[columnId - 2].setColumnMedicineActive();
        this.closeAllDropdown(columnId - 1);
      }
    },
    onChangeFocusRight(columnId) {
      if (columnId < this.scene.length) {
        this.$refs.columnMedicine[columnId].setColumnMedicineActive();
        this.closeAllDropdown(columnId - 1);
      }
    },
    startIndex(colIndex) {
      return this.scene
        .slice(0, colIndex)
        .reduce((accumulator, currentValue) => accumulator + currentValue.children.length, 0);
    },
    onCreateNewColumn() {
      this.modelNumberColumn = this.scene.length + 1;
      const index = this.scene.length - 1;
      setTimeout(() => {
        this.$refs.columnMedicine[index].openColumnMedicineDropdown();
      }, 0);
    },
    closeAllDropdown(index) {
      setTimeout(() => {
        this.$refs.columnMedicine[index].closeAllDropdown();
      })
    },
    checkDuplicate() {
      const dupplicates = {};
      this.scene.forEach((columnElment, colIndex) => {
        columnElment.children.forEach((cellElement, cellIndex) => {
          this.scene[colIndex].children[cellIndex].isDupplicate = false;
          if (!cellElement.medicineId) return;
          if (!dupplicates[cellElement.medicineId]) {
            dupplicates[cellElement.medicineId] = {
              count: 0,
              colIndex: [colIndex],
              cellIndex: [cellIndex],
            };
            return;
          }
          dupplicates[cellElement.medicineId].count += 1;
          dupplicates[cellElement.medicineId].colIndex.push(colIndex);
          dupplicates[cellElement.medicineId].cellIndex.push(cellIndex);
        });
      });

      Object.entries(dupplicates).forEach(([_, element]) => {
        if (element.count) {
          element.colIndex.forEach((value, index) => {
            this.scene[value].children[element.cellIndex[index]].isDupplicate = true;
          });
        }
      });
    },

    async checkMass(medicineId, colIndex, cellIndex) {
      try {
        await this.$services.prescriptionService.checkMass(medicineId, {
          mass: this.scene[colIndex].children[cellIndex].mass || 0,
        });
        this.scene[colIndex].children[cellIndex].isOverMass = false;
      } catch (err) {
        this.scene[colIndex].children[cellIndex].isOverMass = true;
      }
    },

    onCardDrop(columnId, dropResult) {
      let columnIndex = null;
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const scene = [...this.scene];
        const column = scene.find((p) => p.columnId === columnId);
        columnIndex = scene.indexOf(column);

        const newColumn = Object.assign({}, column);
        newColumn.children = applyDrag(newColumn.children, dropResult);
        this.scene.splice(columnIndex, 1, newColumn);
      }

      if (dropResult.removedIndex !== null) {
        this.count += 1 ;
      }

      if (dropResult.addedIndex !== null) {
        this.count += 1 ;
      }

      if (this.count === 2) {
        const indexEmpty = this.scene.findIndex((element) => !element.children.length);
        if (indexEmpty !== -1) {
          this.scene.splice(indexEmpty, 1);
          this.scene.forEach((element, i) => {
            element.columnId = i + 1;
          });
        }
        this.count = 0;
      }
    },

    onColumnDrop(dropResult) {
      const scene = [...this.scene];
      scene = applyDrag(scene, dropResult);
      this.scene = scene;
    },

    onRemoveColumn(columnId, index) {
      const columnIndex = this.scene.findIndex((p) => p.columnId === columnId);
      this.scene.splice(columnIndex, 1);
      this.scene.forEach((element, i) => {
        element.columnId = i + 1;
      });
      if (this.scene.length > 0) {
        if (columnIndex === this.scene.length) {
          this.$refs.columnMedicine[columnIndex - 1].setColumnMedicineActive();
          return;
        }
        this.$refs.columnMedicine[columnIndex].setColumnMedicineActive();
      }
    },

    onRemoveRow(columnId, rowIndex) {
      const columnIndex = this.scene.findIndex((p) => p.columnId === columnId);
      if (columnIndex !== undefined) {
        this.scene[columnIndex].children.splice(rowIndex, 1);
        
        if (this.scene[columnIndex].children.length === 0) {
          this.scene.splice(columnIndex, 1);
          this.scene.forEach((element, i) => {
            element.columnId = i + 1;
          });

          if (this.scene.length > 0) {
            if (columnIndex === this.scene.length) {
              this.$refs.columnMedicine[columnIndex - 1].setColumnMedicineActive();
              return;
            }
            this.$refs.columnMedicine[columnIndex].setColumnMedicineActive();
          }
        } else {
          if (rowIndex === this.scene[columnIndex].children.length) {
            this.$refs.columnMedicine[columnIndex].setCardMedicineActive(rowIndex - 1);
            return;
          }
          this.$refs.columnMedicine[columnIndex].setCardMedicineActive(rowIndex);
        }
      }
    },
  },
};
</script>

<style scoped>
.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px 45px 5px 5px;
}

.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}

.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.opacity-ghost-drop {
  opacity: 1;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  opacity: 0.8;
  border: 2px solid transparent;
  border-radius: 5px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
