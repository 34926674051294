<template>
  <v-dialog
    :value="true"
    @keydown="onKeyDown"
    max-width="80%"
    style="height: calc(100vh - 20%)"
    persistent
  >
    <v-card>
      <v-card-title>Đơn thuốc</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form ref="form" v-model="valid" lazy-validation>
            <PatientInformation class="mb-5" />
            <MedicineSection class="mb-5" :scene="scene" :isValidated="true" />
            <FooterCard :createdBy="createdBy" @close="onClose" @save="onSave" />
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { Container, Draggable } from "vue-dndrop";
import { generateItems } from "~/utils";
import InputNumber from "~/components/molecules/InputNumber";
import MedicineSection from "~/components/organisms/MedicineSection";
import PatientInformation from "~/components/organisms/prescription/PatientInformation";
import FooterCard from "~/components/organisms/prescription/FooterCard";
import { debounce } from "~/utils";

export default {
  name: "PrescriptionDialog",
  props: {
    isCreateMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Container,
    Draggable,
    InputNumber,
    MedicineSection,
    PatientInformation,
    FooterCard,
  },

  data() {
    return {
      scene: [],
      valid: true,
      errors: [],
      calculation: debounce(async () => {
        await this.$store.dispatch(
          "prescription/calculatePrice",
          this.prescriptionCalculated
        );
        this.$store.commit("prescription/disableLoadingPrice");
      }, 800),
    };
  },

  created() {
    if (this.prescription?.length) {
      this.scene = [...structuredClone(this.prescription)];
      this.scene = this.scene.map((element) => ({
        columnId: element.columnId,
        children: (() => {
          return [
            ...element.children.map((child) => ({
              ...child,
              isDupplicate: false,
              isOverMass: false,
            })),
          ];
        })(),
      }));
      return;
    }

    this.scene = generateItems(1, (i) => ({
      columnId: i,
      children: generateItems(1, (j) => ({
        medicineId: null,
        mass: null,
        isHidden: false,
        isDupplicate: false,
        isOverMass: false,
      })),
    }));
  },

  computed: {
    ...mapState({
      prescription: (state) => state.prescription.prescription,
      detailPrescription: (state) => state.prescription.detailPrescription,
      medicineList: (state) => state.medicineList,
      userProfile: (state) => state.userProfile,
    }),
    prescriptionCalculated() {
      return this.scene?.reduce((accumulator, currentValue) => {
        const medicines = currentValue.children.filter(
          (element) => element.medicineId && element.mass
        );
        if (medicines?.length) {
          accumulator.push({
            columnId: currentValue.columnId,
            children: [...medicines],
          });
        }
        return accumulator;
      }, []);
    },
    createdBy() {
      return this.isCreateMode
        ? this.userProfile?.name || ""
        : this.$store.state.prescription?.createdBy || "";
    },
  },

  watch: {
    prescriptionCalculated: {
      handler() {
        this.$store.commit("prescription/enableLoadingPrice");
        this.calculation();
      },
      deep: true,
    },
    "detailPrescription.quantity": {
      handler() {
        this.$store.commit("prescription/enableLoadingPrice");
        this.calculation();
      },
    },
  },

  methods: {
    onClose() {
      this.$emit("close");
    },
    async onSave() {
      await this.$refs.form.validate();
      if (!this.valid) return;
      this.$store.commit("prescription/setData", {
        prescription: this.scene,
      });
      this.$emit("close");
    },
    onKeyDown(e) {
      if (e.key === "S" && e.shiftKey) {
        this.onSave();
        return;
      }
      if (e.key === "Escape") {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  opacity: 0.8;
  border: 2px solid transparent;
  border-radius: 5px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
