<template>
  <v-container fluid>
    <v-row>
      <Container
        group-name="col"
        @drop="(e) => onCardDrop(columnId, e)"
        :get-child-payload="getCardPayload(columnId)"
        drag-handle-selector=".cell-drag-handle"
        drop-class="opacity-ghost-drop"
        :drop-placeholder="dropPlaceholderOptions"
      >
        <Draggable
          v-for="(element, i) in list"
          :key="`${columnId + i}`"
          :tag="{
            value: 'div',
            props: { class: 'mb-2' },
          }"
        >
          <CardMedicine
            ref="cardMedicine"
            class="cell-drag-handle w-400/16"
            :card="element"
            :ordered="startIndex + i + 1"
            :isValidated="isValidated"
            :medicineList="medicineList"
            @next-line="onCreateNewRow"
            @check:mass="checkMass(element.medicineId, i)"
            @check:duplicate="checkDuplicate"
            @remove-comlun="onRemoveColumn(i)"
            @remove-row="onRemoveRow(i)"
            @remove="onRemoveRow(i)"
            @change-focus-left="$emit('change-focus-left', columnId)"
            @change-focus-right="$emit('change-focus-right', columnId)"
            @create-new-column="onCreateNewColumn"
          />
        </Draggable>
      </Container>
    </v-row>
  </v-container>
</template>

<script>
import { Container, Draggable } from "vue-dndrop";
import CardMedicine from "~/components/atoms/CardMedicine";

export default {
  name: "ColumnMedicine",
  components: { Container, Draggable, CardMedicine },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    columnId: {
      type: Number,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isValidated: {
      type: Boolean,
      default: true,
    },
    startIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "300",
        showOnTop: true,
      },
    };
  },

  computed: {
    medicineList() {
      return this.$store.state.medicineList
    }
  },

  methods: {
    setCardMedicineActive(index) {
      setTimeout(() => {
        this.$refs.cardMedicine[index].onFocusDropdown();
      }, 0);
    },
    setColumnMedicineActive() {
      setTimeout(() => {
        this.$refs.cardMedicine[0].onFocusDropdown();
      }, 0);
    },
    openColumnMedicineDropdown() {
      setTimeout(() => {
        this.$refs.cardMedicine[0].onOpenDropdown();
      }, 0);
    },
    closeAllDropdown() {
      this.$refs.cardMedicine.forEach((card) => {
        card.onCloseDropdown();
      });
    },
    onCreateNewRow() {
      this.list.splice(this.list.length, 0, {
        medicineId: null,
        mass: null,
        isHidden: false,
        isDupplicate: false,
        isOverMass: false,
      });
      const index = this.list.length - 1;
      setTimeout(() => {
        this.$refs.cardMedicine[index].onOpenDropdown();
      }, 0);
    },

    onCreateNewColumn() {
      this.closeAllDropdown();
      this.$emit("create-new-column", this.columnId);
    },

    checkDuplicate() {
      this.$emit("check:duplicate");
    },

    async checkMass(medicineId, cellIndex) {
      this.$emit("check:mass", medicineId, cellIndex);
    },

    onRemoveColumn(index) {
      this.$emit("remove-column", this.columnId, index);
    },

    onRemoveRow(index) {
      this.$emit("remove-row", this.columnId, index);
    },

    onCardDrop(_, dropResult) {
      this.$emit("drop-card", this.columnId, dropResult);
    },

    getCardPayload() {
      return (index) => {
        return this.list[index];
      };
    },
  },
};
</script>

<style scoped>
.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px 5px 5px 5px;
}

.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}

.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.opacity-ghost-drop {
  opacity: 1;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}
</style>
