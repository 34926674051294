<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="2">
        <v-text-field
          prepend-inner-icon="mdi-numeric"
          v-model="quantity"
          outlined
          hide-details
          label="Số thang"
          @keypress="filter(evt)"
          type="number"
          :min="1"
          dense
        />
      </v-col>
      <v-col cols="2">
        <DatePicker v-model="reExamination" dense label="Ngày tái khám" />
      </v-col>
      <v-col cols="4">
        <v-textarea
          rows="1"
          auto-grow
          prepend-inner-icon="mdi-comment-question"
          v-model="howToUse"
          outlined
          hide-details
          dense
          label="Cách dùng"
        />
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="4">
        <v-textarea
          rows="1"
          auto-grow
          prepend-inner-icon="mdi-notebook-edit-outline"
          v-model="noteForDosing"
          outlined
          hide-details
          dense
          label="Lưu ý cho bốc, sắc, giao thuốc"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="3">
        <money-input
          v-model="price"
          label="Thành tiền"
          dense
          outlined
          clearable
          :options="options"
          :properties="properties"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="doctorId"
          :items="doctorList"
          :rules="[rules.required]"
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          dense
          label="Bác sỹ"
        >
          <template #selection="data">
            <v-avatar size="32" left>
              <v-img :src="data.item.avatar" />
            </v-avatar>
            {{ data.item.name }}
          </template>
          <template #item="data">
            <v-avatar left>
              <v-img :src="data.item.avatar" />
            </v-avatar>
            {{ data.item.name }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row class="justify-end">
      <v-col class="d-flex justify-end space-x-2" cols="2">
        <v-btn depressed dark color="#474747" @click="onCancel"> Hủy </v-btn>
        <v-btn depressed dark color="#FBB040" @click="onSave"> Lưu </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "~/components/atoms/DatePicker.vue";
import MoneyInput from "~/components/atoms/MoneyInput.vue";
import { mapState } from "vuex";
import { EMPTY } from "~/utils/constants";
import { required } from "~/utils/validation";

export default {
  name: "FooterCard",
  components: { DatePicker, MoneyInput },
  data() {
    return {
      preQuantity: 1,
      preHowToUse: EMPTY,
      preNoteForDosing: EMPTY,
      preReExamination: EMPTY,
      prePrice: 0,
      prePriceFormat: EMPTY,
      rules: {
        required,
      },
      options: {
        locale: "it-IT",
        prefix: "",
        suffix: "vnđ",
        length: 30,
        precision: 0,
      },
      properties: {
        hint: "Chú ý đơn vị tính",
        prependInnerIcon: "mdi-currency-usd",
        color: "#FBB040",
      },
      evt: null
    };
  },
  created() {
    this.preQuantity = this.detail.quantity;
    this.preHowToUse = this.detail.howToUse;
    this.preNoteForDosing = this.detail.noteForDosing;
    this.preReExamination = this.detail.reExamination;
    this.prePrice = this.detail.price;
    this.prePriceFormat = this.detail.priceFormat;
  },
  computed: {
    ...mapState({
      detail: (state) => state.prescription.detailPrescription,
      doctorIdStore: (state) => state.prescription.doctorId,
      doctorList: (state) => state.doctorList,
    }),
    doctorId: {
      get() {
        return this.doctorIdStore;
      },
      set(doctorId) {
        this.$store.commit("prescription/setData", { doctorId });
      },
    },
    quantity: {
      get() {
        return this.detail.quantity;
      },
      set(quantity) {
        this.$store.commit("prescription/setPropertyDetails", { quantity });
      },
    },
    reExamination: {
      get() {
        return this.detail.reExamination;
      },
      set(reExamination) {
        this.$store.commit("prescription/setPropertyDetails", { reExamination });
      },
    },
    howToUse: {
      get() {
        return this.detail.howToUse;
      },
      set(howToUse) {
        this.$store.commit("prescription/setPropertyDetails", { howToUse });
      },
    },
    noteForDosing: {
      get() {
        return this.detail.noteForDosing;
      },
      set(noteForDosing) {
        this.$store.commit("prescription/setPropertyDetails", { noteForDosing });
      },
    },
    price: {
      get() {
        return this.detail.price;
      },
      set(price) {
        this.$store.commit("prescription/setPropertyDetails", { price });
      },
    },
  },
  methods: {
    filter(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onSave() {
      this.$emit("save");
    },
    onCancel() {
      this.$store.commit("prescription/setPropertyDetails", {
        quantity: this.preQuantity,
        howToUse: this.preHowToUse,
        noteForDosing: this.preNoteForDosing,
        reExamination: this.preReExamination,
        price: this.prePrice,
        priceFormat: this.prePriceFormat,
      });
      this.$emit("close");
    },
  },
};
</script>
