<template>
  <v-dialog
    :value="true"
    max-width="80%"
    style="height: calc(100vh - 20%)"
    @keydown="onKeyDown"
    @click:outside="onClose"
  >
    <v-card>
      <v-card-title>Đơn thuốc</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form ref="form" v-model="valid" lazy-validation>
            <PatientInformation class="mb-5" />
            <MedicineSection class="mb-5" :scene="scene" :isValidated="false" />
            <FooterCard @close="onClose" @save="onSave" />
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { Container, Draggable } from "vue-dndrop";
import { generateItems } from "~/utils";
import InputNumber from "~/components/molecules/InputNumber";
import MedicineSection from "~/components/organisms/MedicineSection";
import PatientInformation from "~/components/organisms/oldPrescription/PatientInformation";
import FooterCard from "~/components/organisms/oldPrescription/FooterCard";

export default {
  name: "OldPrescriptionDialog",
  components: {
    Container,
    Draggable,
    InputNumber,
    MedicineSection,
    PatientInformation,
    FooterCard,
  },

  data() {
    return {
      scene: [],
      valid: true,
    };
  },

  created() {
    this.$store.dispatch("getListDoctor");
    if (this.prescription?.length) {
      this.scene = [...structuredClone(this.prescription)];
      this.scene = this.scene.map((element) => ({
        columnId: element.columnId,
        children: (() => {
          return [
            ...element.children.map((child) => ({
              ...child,
              isDupplicate: false,
              isOverMass: false,
            })),
          ];
        })(),
      }));
      return;
    }
    this.scene = generateItems(1, (i) => ({
      columnId: i,
      children: generateItems(1, (j) => ({
        medicineId: null,
        mass: null,
        isHidden: false,
        isDupplicate: false,
        isOverMass: false,
      })),
    }));
  },

  computed: {
    ...mapState({
      prescription: (state) => state.prescription.prescription,
      medicineList: (state) => state.medicineList,
    }),
  },

  methods: {
    onClose() {
      this.$emit("close");
    },
    async onSave() {
      await this.$refs.form.validate();
      if (!this.valid) return;
      this.$store.commit("prescription/setData", {
        prescription: this.scene,
      });
      this.$emit("close");
    },
    onKeyDown(e) {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  opacity: 0.8;
  border: 2px solid transparent;
  border-radius: 5px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>