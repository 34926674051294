<template>
  <v-container class="bg-color-3 text-color-4 rounded-md font-medium text-base">
    <v-row>
      <v-col>
        <span>Bệnh nhân: </span>
        <span>{{ patientInfo.patientName }}</span>
      </v-col>
      <v-col>
        <span>Tuổi: </span>
        <span>{{ patientInfo.age }}</span>
      </v-col>
      <v-col>
        <span>Giới tính: </span>
        <span>{{ patientInfo.gender }}</span>
      </v-col>
      <v-col>
        <DatePicker v-model="doctorAt" label="Ngày kê đơn" dense :rules="rules" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span>Lí do: </span>
        <span>{{ patientInfo.reason }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "~/components/atoms/DatePicker.vue";
import { required } from "~/utils/validation";

export default {
  name: "PatientInformation",
  components: { DatePicker },
  data() {
    return {
      rules: [required],
    };
  },
  computed: {
    patientInfo() {
      return this.$store.getters["prescription/getPatientInformation"];
    },
    doctorAt: {
      get() {
        return this.$store.state.prescription.doctorAt;
      },
      set(doctorAt) {
        this.$store.commit("prescription/setData", { doctorAt });
      },
    },
  },
};
</script>
