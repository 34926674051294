<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="2">
        <v-text-field
          prepend-inner-icon="mdi-numeric"
          v-model="quantity"
          outlined
          hide-details
          dense
          label="Số thang"
          type="number"
          :min="1"
        />
      </v-col>
      <v-col cols="2">
        <DatePicker v-model="reExamination" label="Ngày tái khám" dense />
      </v-col>
      <v-col cols="4">
        <v-textarea
          rows="1"
          auto-grow
          prepend-inner-icon="mdi-comment-question"
          v-model="howToUse"
          outlined
          hide-details
          dense
          label="Cách dùng"
        />
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col cols="4">
        <v-textarea
          rows="1"
          auto-grow
          prepend-inner-icon="mdi-notebook-edit-outline"
          v-model="noteForDosing"
          outlined
          hide-details
          dense
          label="Lưu ý cho bốc, sắc, giao thuốc"
        />
      </v-col>
    </v-row>
    <v-row>
      <div class="flex items-center space-x-2 text-base font-bold">
        <u>Thành tiền</u>:
        <v-skeleton-loader
          :loading="isLoadingPrice"
          transition="scale-transition"
          :boilerplate="false"
          :types="{ skeleton: 'text' }"
          type="skeleton"
          height="20px"
          width="200px"
        >
          <strong class="text-black">{{ detail.priceFormat }}</strong>
        </v-skeleton-loader>
      </div>
    </v-row>
    <v-row class="justify-between">
      <v-col cols="10" class="pa-0 d-flex items-center">
        <span class="text-base font-medium">Lên đơn bởi: {{ createdBy }}</span>
      </v-col>
      <v-col class="d-flex justify-end space-x-2" cols="2">
        <v-btn depressed dark color="#474747" @click="onCancel"> Hủy </v-btn>
        <v-btn depressed dark color="#FBB040" @click="onSave"> Lưu </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "~/components/atoms/DatePicker.vue";
import { mapState, mapGetters } from "vuex";
import { EMPTY } from "~/utils/constants";

export default {
  name: "FooterCard",
  components: { DatePicker },
  props: {
    createdBy: {
      type: String,
      default: EMPTY,
    },
  },
  data() {
    return {
      preQuantity: 1,
      preHowToUse: EMPTY,
      preNoteForDosing: EMPTY,
      preReExamination: EMPTY,
      prePrice: 0,
      prePriceFormat: EMPTY,
    };
  },
  created() {
    this.preQuantity = this.detail.quantity;
    this.preHowToUse = this.detail.howToUse;
    this.preNoteForDosing = this.detail.noteForDosing;
    this.preReExamination = this.detail.reExamination;
    this.prePrice = this.detail.price;
    this.prePriceFormat = this.detail.priceFormat;
  },
  computed: {
    ...mapState({
      detail: (state) => state.prescription.detailPrescription,
    }),
    ...mapGetters({
      isLoadingPrice: "prescription/isLoadingPrice",
    }),
    quantity: {
      get() {
        return this.detail.quantity;
      },
      set(quantity) {
        this.$store.commit("prescription/setPropertyDetails", { quantity });
      },
    },
    reExamination: {
      get() {
        return this.detail.reExamination;
      },
      set(reExamination) {
        this.$store.commit("prescription/setPropertyDetails", { reExamination });
      },
    },
    howToUse: {
      get() {
        return this.detail.howToUse;
      },
      set(howToUse) {
        this.$store.commit("prescription/setPropertyDetails", { howToUse });
      },
    },
    noteForDosing: {
      get() {
        return this.detail.noteForDosing;
      },
      set(noteForDosing) {
        this.$store.commit("prescription/setPropertyDetails", { noteForDosing });
      },
    },
  },
  methods: {
    onSave() {
      this.$emit("save");
    },
    onCancel() {
      this.$store.commit("prescription/setPropertyDetails", {
        quantity: this.preQuantity,
        howToUse: this.preHowToUse,
        noteForDosing: this.preNoteForDosing,
        reExamination: this.preReExamination,
        price: this.prePrice,
        priceFormat: this.prePriceFormat,
      });
      this.$emit("close");
    },
  },
};
</script>
