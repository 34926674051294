<template>
  <v-text-field
    hide-spin-buttons
    class="centered-input"
    :class="classes"
    type="number"
    append-outer-icon="mdi-plus"
    prepend-icon="mdi-minus"
    :value="computedValue"
    :label="label"
    :min="min"
    :max="max"
    :step="step"
    @click:append-outer="increament"
    @click:prepend="decrement"
    tabindex="-1"
  />
</template>

<script>
export default {
  name: "BInput",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    centeredTextInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  computed: {
    classes() {
      return {
        "centered-text": this.centeredTextInput,
      };
    },
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value() {
      this.newValue = this.value;
    },
  },
  methods: {
    increament() {
      const value = this.newValue + this.step;
      if (value <= this.max) {
        this.newValue = value;
      }
      this.computedValue = this.newValue;
    },
    decrement() {
      const value = this.newValue - this.step;
      if (value >= this.min) {
        this.newValue = value;
      }
      this.computedValue = this.newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
input:disabled {
  color: #828282;
  background-color: rgb(242, 242, 242);
  cursor: not-allowed;
}
</style>
