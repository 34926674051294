<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="2"
        ><v-text-field
          prepend-inner-icon="mdi-numeric"
          v-model="modelQuantity"
          type="number"
          outlined
          hide-details
          dense
          label="Số thang"
          :min="1"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row class="justify-between">
      <v-col cols="10" class="pa-0 d-flex items-center">
        <span class="text-base font-medium">Lên đơn bởi: {{ userProfile.name }}</span>
      </v-col>
      <v-col class="d-flex justify-end space-x-2" cols="2">
        <v-btn depressed dark color="#474747" @click="() => $emit('close')"> Hủy </v-btn>
        <v-btn depressed dark color="#FBB040" @click="() => $emit('save')"> Lưu </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FooterCard",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      quantity: (state) => state.prescriptionBase.quantity,
      userProfile: (state) => state.userProfile,
    }),
    modelQuantity: {
      get() {
        return this.quantity;
      },
      set(value) {
        this.$store.commit("prescriptionBase/setData", { quantity: value });
      },
    },
  },
  methods: {},
};
</script>
